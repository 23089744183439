<template>
  <SettingsCard
    :title="$t('settings.crm.title')"
    :subtitle="$t('settings.crm.subtitle', { readablePlatformName })"
    icon="mdi-account-sync-outline"
    is-default-open
    :hide-header="slimMode"
  >
    <v-row class="mx-0 my-2 mx-md-2">
      <v-col
        v-if="!slimMode"
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.crm.selection-texts.0') }}
        </div>
        <div class="grey--text">
          <p>{{ $t('settings.crm.selection-texts.1') }}</p>
          <p>{{ $t('settings.crm.selection-texts.2', { readablePlatformName }) }}.</p>
        </div>
      </v-col>
      <v-col
        v-if="!slimMode"
        class="pt-2 pt-md-8"
        cols="12"
        offset-md="1"
        md="5"
      >
        <v-tooltip
          top
          :disabled="!hasBeenActivated"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="w-full"
              v-on="on"
            >
              <v-select
                v-model="crmProvider"
                :items="providers"
                :disabled="!!loadingPart || hasBeenActivated"
                :loading="loadingPart === 'crmProvider'"
                label="CRM"
                outlined
                required
                hide-details
                v-bind="attrs"
                @change="(value) => update({ crmProvider: value }, 'crmProvider')"
              />
            </div>
          </template>
          <span>{{ $t('settings.crm.help-texts.0') }}</span>
        </v-tooltip>
        <div class="mx-2">
          <span
            class="text-body-2"
          >
            {{ $t('settings.crm.help-texts.1') }}
            <a
              :href="helpLink"
              target="_blank"
            >
              {{ $t('settings.crm.help-texts.2') }}
            </a>
          </span>
        </div>
      </v-col>
    </v-row>
    <component
      :is="credentialsComponent"
      v-if="crmProvider"
      :disabled="!!loadingPart || isActive"
      @isVerified="(value) => isConnectionVerified = value"
    />
    <component
      :is="filterComponent"
      v-if="isConnectionVerified"
      id="CrmFilter"
      :disabled="!!loadingPart || isActive"
      @isFilterSelected="setSelectedFilter"
    />
    <v-row
      v-if="isConnectionVerified && isFilterSelected"
      class="mx-0 my-2 mx-md-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.crm.synch-texts.0') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.crm.synch-texts.1') }}
        </div>
      </v-col>
      <v-col
        class="flex-row pt-0 pb-6 pt-md-4 d-flex align-center justify-md-end"
        cols="12"
        offset-md="1"
        md="5"
      >
        <div
          class="pr-2 mt-5 switch-toggle grey--text"
          :class="{'text--darken-1': !loadingPart, 'text--lighten-1': !!loadingPart}"
          @click="toggleSwitch"
        >
          {{ $t('labels.manual') }}
        </div>
        <v-switch
          v-model="automaticSync"
          :label="$t('labels.automatic')"
          :loading="loadingPart === 'syncModus'"
          :disabled="!!loadingPart"
          hide-details
          @change="(value) => update({ syncModus: value ? 'AUTO' : 'MANUALLY' }, 'syncModus')"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="isConnectionVerified && isFilterSelected"
      class="mx-0 my-2 mx-md-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          {{ $t('settings.crm.synch-texts.2') }}
        </div>
        <div class="grey--text">
          {{ $t('settings.crm.synch-texts.3') }}
        </div>
      </v-col>
      <v-col
        class="pt-2 pt-md-8 d-flex flex-column align-md-end"
        cols="12"
        offset-md="1"
        md="5"
      >
        <SynchronisationState
          class="mb-4"
          :is-active="isActive"
        />
        <ActivateSyncDialog
          v-if="!isActive"
          id="ActivateSyncDialog"
          :disabled="!!loadingPart"
          :loading="loadingPart === 'isActive'"
          :automatic-sync="automaticSync"
          @activate="() => update({ isActive: true }, 'isActive' )"
        />
        <DeactivateSyncDialog
          v-else
          :disabled="!!loadingPart"
          :loading="loadingPart === 'isActive'"
          :automatic-sync="automaticSync"
          @deactivate="() => update({ isActive: false }, 'isActive' )"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!crmProvider"
      class="mx-0 my-2 mx-md-2"
    >
      <v-alert
        icon="mdi-lightbulb-on-20"
        prominent
        text
        type="info"
        class="w-full pl-8 my-4"
      >
        <p class="pl-6 ma-0 font-weight-bold">
          {{ $t('labels.our-tip') }}:
        </p>
        <p class="pl-6">
          {{ $t('settings.crm.info-texts.0') }}<br>
          {{ $t('settings.crm.info-texts.1') }}<br>
          {{ $t('settings.crm.info-texts.2') }}<br>
          {{ $t('settings.crm.info-texts.3') }} <a href="/leads">{{ $t('settings.crm.info-texts.4') }}</a>
        </p>
      </v-alert>
    </v-row>
  </SettingsCard>
</template>

<script>
import CREATE_OR_UPDATE_CRM_COMPANY_CONFIG from './queries/CreateOrUpdateCrmCompanyConfig.gql'
import SettingsCard from '@/components/SettingsCard.vue'
import featureMixin from '@/mixins/feature'
import eventBus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'
import SynchronisationState from './SynchronisationState.vue'
import ActivateSyncDialog from './dialogs/ActivateSyncDialog.vue'
import DeactivateSyncDialog from './dialogs/DeactivateSyncDialog.vue'
import PropstackCredentials from './propstack/PropstackCredentials.vue'
import PropstackFilter from './propstack/PropstackFilter.vue'
import OnofficeFilter from './onoffice/OnofficeFilter.vue'
import FlowfactCredentials from './flowfact/FlowfactCredentials.vue'
import FlowfactFilter from './flowfact/FlowfactFilter.vue'
import crmMixin from '@/mixins/crm'
import OnofficeCredentials from './onoffice/OnofficeCredentials.vue'

export default {
  components: {
    SettingsCard,
    PropstackCredentials,
    SynchronisationState,
    ActivateSyncDialog,
    DeactivateSyncDialog,
    PropstackFilter,
    OnofficeCredentials,
    OnofficeFilter,
    FlowfactCredentials,
    FlowfactFilter
  },
  mixins: [featureMixin, brandingMixin, crmMixin],
  props: {
    hideHelp: {
      type: Boolean,
      default: false
    },
    slimMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      crmProvider: null,
      isActive: false,
      hasBeenActivated: false,
      automaticSync: null,
      isConnectionVerified: false,
      isFilterSelected: false,
      loadingPart: false,
      isHelpHidden: this.hideHelp
    }
  },
  computed: {
    credentialsComponent () {
      return {
        PROPSTACK: 'PropstackCredentials',
        ONOFFICE: 'OnofficeCredentials',
        FLOWFACT: 'FlowfactCredentials'
      }[this.crmProvider]
    },
    filterComponent () {
      return {
        PROPSTACK: 'PropstackFilter',
        ONOFFICE: 'OnofficeFilter',
        FLOWFACT: 'FlowfactFilter'
      }[this.crmProvider]
    },
    helpLink () {
      return {
        PROPSTACK: 'https://scribehow.com/shared/Propstack_mit_BOTTIMMO_synchronisieren__98hEYNQbQamsQVJV3no6RQ',
        ONOFFICE: 'https://scribehow.com/shared/onOffice_mit_BOTTIMMO_verbinden__sUIm44idQpeHIDqdJnHIZA',
        FLOWFACT: 'https://scribehow.com/shared/FLOWFACT_mit_BOTTIMMO_synchronisieren__HIuNi9KqT6GQKhBDZvF4jA'
      }[this.crmProvider] || 'https://hilfe.bottimmo.com/portal/de/kb/general/einstellungen/crm'
    },
    isHorizontal () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    crmConfig: {
      deep: true,
      handler (val) {
        this.updateData(val || {})
      }
    },
    async isConnectionVerified (val) {
      if (val) {
        await this.$nextTick()
        return document.querySelector('#CrmFilter')?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  mounted () {
    this.updateData(this.crmConfig || {})
  },
  methods: {
    toggleSwitch () {
      this.automaticSync = !this.automaticSync
      this.update({ syncModus: this.automaticSync ? 'AUTO' : 'MANUALLY' }, 'syncModus')
    },
    updateData (config) {
      this.crmProvider = config.crmProvider
      this.automaticSync = config.syncModus === 'AUTO'
      this.isActive = config.isActive
      this.hasBeenActivated = config.hasBeenActivated
    },
    async update (values, loadingPart) {
      this.loadingPart = loadingPart
      try {
        await new Promise(resolve => setTimeout(resolve, 700))
        await this.$apollo.mutate({
          mutation: CREATE_OR_UPDATE_CRM_COMPANY_CONFIG,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              crmProvider: this.crmProvider,
              isActive: this.isActive,
              syncModus: this.automaticSync ? 'AUTO' : 'MANUALLY',
              ...values
            }
          }
        })
        eventBus.$emit(eventNames.CRM_UPDATED)
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.crm.success') })
      } catch (err) {
        await this.$apollo.queries.config.refetch()
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.crm.error') })
      } finally {
        this.loadingPart = false
      }
    },
    setSelectedFilter (value) {
      this.isFilterSelected = value
      this.$emit('isFilterSelected', value)
      setTimeout(() => {
        return document.querySelector('#ActivateSyncDialog')?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch-toggle {
  cursor: pointer;
}
</style>
